import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import MaskGroup145 from "../../../../../assets/images/StrategicGame/ascending_arrow.png";
import {useDispatch, useSelector} from "react-redux";
import "./style.scss"
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import icon from "../../../../../assets/images/pvPharma5Game/images/equals-solid.svg"
import { day4Step4UpdateDecisions} from "../../../../../redux/daysPV5/actions";

const Drag = ({
                  listP,
                  item,
                  modeEdit,
                  onDrop = () => null,
                  onDragOver = () => null,
                  t,
              }) => {
    const onDragStart = (ev, id) => {
        ev.dataTransfer.setData("id", id);
    };
    return (
        <div
            className="drag-block"
            onDrop={(ev, cat) => onDrop(ev, cat, item.id)}
            onDragOver={onDragOver}
        >
            <h3 className="title m-0"> {t(`pvPharma5game.day4.part4.categories.${item.id}`)} </h3>
            <div className="body mt-1">
                {listP.map((elem, index) => {
                    if (elem.category === item.id) {
                        return (
                            <div
                                key={index}
                                className="item"
                                draggable={modeEdit}
                                onDragStart={(e) => onDragStart(e, elem.id)}
                            >
                                <img src={icon} alt={""}/>
                                <p title={t(`pvPharma5game.day4.part4.${elem.id}`)}>
                                    {t(`pvPharma5game.day4.part4.${elem.id}`)}
                                </p>
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
};

const Step2 = ({modeEdit}) => {
    const { decisions, categories } = useSelector((state) => state.DaysPha5.day4.part4);
    const { loading } = useSelector((state) => state.DaysPha5);

    // const [modeEdit, setModeEdit] = useState(true);
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(categories);

    const dispatch = useDispatch();
    const [listP, setListP] = useState(decisions);


    useEffect(() => {
        const d = decisions.sort(() => Math.random() - 0.5);
        setListP(d);
    }, []);

    const onDragStart = (ev, id) => {
        //console.log("dragstart:", id);
        ev.dataTransfer.setData("id", id);
    };

    const onDragOver = (ev) => {
        ev.preventDefault();
    };

    const onDrop = (ev, cat, idBlock) => {
        //console.log("idBlock", idBlock);

        if (idBlock) {
            let id = ev.dataTransfer.getData("id");

            let tasks_ = listP.map((task) => {
                if (task.id == id) {
                    task.category = idBlock;
                }
                return task;
            });
            //setListP(tasks_);
            dispatch(day4Step4UpdateDecisions(tasks_));
        } else {
            let id = ev.dataTransfer.getData("id");

            //console.log("===id===", id);
            let tasks_ = listP.map((task) => {
                if (task.id == id) {
                    task.category = "block";
                }
                return task;
            });

            dispatch(day4Step4UpdateDecisions(tasks_));
        }
    };

    return (
            <div className="box-2-3-step-2 ">
                <div className="block-2_day4-ph5">
                    <div
                        className="list-dr-ph5"
                        onDrop={(ev) => onDrop(ev)}
                        onDragOver={onDragOver}
                    >
                        {listP.map((item, index) => {
                            if (item.category === "block") {
                                return (
                                    <div
                                        key={index}
                                        draggable={modeEdit}
                                        onDragStart={(e) => onDragStart(e, item.id)}
                                        className="list-dr-item"
                                    >
                                      <img src={icon} alt={""}/>  <span>
                                        {t(`pvPharma5game.day4.part4.${item.id}`)}
                                    </span>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
                <div className="block-2">
                    <div className="block-2-1">
                        <img src={MaskGroup145} />
                    </div>
                    <div className="block-2-2">
                        <div className="block-2-2row">
                            {tasks.map((item, index) => {
                                if (item.block === 1)
                                    return (
                                        <Drag
                                            key={index}
                                            modeEdit={modeEdit}
                                            listP={listP}
                                            item={item}
                                            onDrop={onDrop}
                                            onDragOver={onDragOver}
                                            t={t}
                                        ></Drag>
                                    );
                            })}
                        </div>

                        <div className="block-2-2row mt-2">
                            {tasks.map((item, index) => {
                                if (item.block === 2)
                                    return (
                                        <Drag
                                            key={index}
                                            modeEdit={modeEdit}
                                            listP={listP}
                                            item={item}
                                            onDrop={onDrop}
                                            onDragOver={onDragOver}
                                            t={t}
                                        ></Drag>
                                    );
                            })}
                        </div>
                    </div>
                </div>
            </div>
    );
};

Step2.propTypes = {};
Step2.defaultProps = {};

export default Step2;
