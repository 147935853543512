import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import img1 from '../../../../assets/images/pvPharma5Game/images/research-center.png'
import img2 from '../../../../assets/images/pvPharma5Game/images/group_8048.svg'
import CustomizedSelect from "../CustomizedSelect/CustomizedSelect";
import img3 from "../../../../assets/images/pvPharma5Game/images/path_12988.svg";

const ModalTest1 = props => {

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);


    if (!props.show) return null;

    const next = () => {
        props.onValidate()
    }

    const cancel = () => {
        props.onClose()

    }


    const item = {
        options: [
            {
                value: 118,
                label: "La notification/déclaration : les effets indésirables sont déclarés aux autorités de tutelle (CNPV et DMP) dans les délais impartis"
            },
            {
                value: 115,
                label: "Le recueil : le RPV recueil les données de notification par différentes sources (Force de vente, patients, professionels de la santé, ...). Le receuil se fait via la fiche de notification"
            },
            {
                value: 120,
                label: "La soumission des rapports périodiques aux autorités"
            },
            {
                value: 116,
                label: "L'enregistrement : les notifications d'EI sont enregistrés dans la base de données du groupe PHARMA5"
            },


            {
                value: 119,
                label: "L’évaluation : le CNPV étudie le lien causal susceptible d'exister entre un événement indésirable et l'administration du médicament"
            },

            {
                value: 121,
                label: "La mise en place d'un Plan de gestion de risque (PGR)"
            },
            {
                value: 117,
                label: "La diffusion : les signalements d’effets indésirables sont transmis par le RPV au Pharmacien Responsable ou son délégué, au Medical Advisor, au Responsable SMQ et au Responsable Affaires Réglementaires"
            },
        ]
    }

    return ReactDOM.createPortal(
        <div className={styles.modal}>
            <div className={styles.modal_content}>

                <div className={styles.block1}>
                    <img src={img2} alt=""/>
                    <h3>Process interne de Pharmacovigilance</h3>
                </div>
                <p className={styles.paragraph}>
                    PHARMA5 dispose d'un process interne pour la Pharmacovigilance.
                    L’exercice de la pharmacovigilance passe par plusieurs étapes.
                    Saurez vous les reconstituez ?

                </p>
                <div className={styles.block3}>
                    <img src={img1} alt=""/>
                    <h4>
                        Les 7 étapes du process interne
                    </h4>
                </div>


                <div className={styles.list}>
                     {/*<img src={img3} alt={""} style={{    transform: "rotate(180deg)"}}/>*/}
                    <CustomizedSelect item={{...item, title: "Etape 1"}} index={"first"} modeEdit={props.modeEdit}/>
                     {/*<img src={img3} alt={""} style={{    transform: "rotate(180deg)"}}/>*/}
                    <CustomizedSelect item={{...item, title: "Etape 2"}} index={"second"} modeEdit={props.modeEdit}/>
                     {/*<img src={img3} alt={""} style={{    transform: "rotate(180deg)"}}/>*/}
                    <CustomizedSelect item={{...item, title: "Etape 3"}} index={"third"} modeEdit={props.modeEdit}/>
                     {/*<img src={img3} alt={""} style={{    transform: "rotate(180deg)"}}/>*/}
                    <CustomizedSelect item={{...item, title: "Etape 4"}} index={"fourth"} modeEdit={props.modeEdit}/>
                     {/*<img src={img3} alt={""} style={{    transform: "rotate(180deg)"}}/>*/}
                    <CustomizedSelect item={{...item, title: "Etape 5"}} index={"fifth"} modeEdit={props.modeEdit}/>
                     {/*<img src={img3} alt={""} style={{    transform: "rotate(180deg)"}}/>*/}
                    <CustomizedSelect item={{...item, title: "Etape 6"}} index={"sixth"} modeEdit={props.modeEdit}/>
                     {/*<img src={img3} alt={""} style={{    transform: "rotate(180deg)"}}/>*/}
                    <CustomizedSelect item={{...item, title: "Etape 7"}} index={"seventh"} modeEdit={props.modeEdit}/>
                </div>

                <div className={styles.modal_footer}>
                    <button className={styles.prv_btn} onClick={cancel}>
                        Annuler
                    </button>
                    <button className={styles.next_btn} onClick={next}>
                        Valider
                    </button>
                </div>

            </div>
        </div>,
        document.getElementById("root")
    );
};

ModalTest1.propTypes = {
    onClose: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,

};


ModalTest1.defaultProps = {};

export default ModalTest1;
