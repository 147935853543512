import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import CryptoJS from "crypto-js";
import { Cookies } from "react-cookie";
import { loginUserSuccess, setModule, setSession } from '../redux/actions';
// import { initDaysData } from "../redux/daysPvCh/actions.js";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom'


const LoaderPage = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();

    const { search } = useLocation()
    const values = queryString.parse(search)

    useEffect(() => {
        const cookies = new Cookies();
        try {
            //         dispatch(initDaysData());

            const bytes = CryptoJS.AES.decrypt(values.token, "4IR8DCqv8AV9Wg&nixq7L9%&fD!pJ");
            const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            const user = { ...data.user, token: data.token }
            dispatch(loginUserSuccess(user));
            cookies.set("user", JSON.stringify(user), { path: '/' });
            console.log("🚀 ~ file: LoaderPage.js:76 ~ useEffect ~ user:", user)


            if (data?.role === "moderator") {
                dispatch(setSession({
                    gameSessionId: data.gameSessionId
                }));
                return history.push("/FinEdge/analysis");
            }
            dispatch(setModule({
                gameSessionId: data.gameSessionId
            }));
            history.push("/FinEdge");

        } catch (e) {
            console.log("error--->", e);
            history.push("/404");
        }
    }, []);

    return (
        <div style={{ width: "100vw", wordWrap: "break-word" }} >
            <p>
                All query params
            </p>

            <Link
                to={`/StratEdge`}
                className="btn btn-indigo text-black"
            >
                <i className="fa fa-trophy mr-1"></i>
            </Link>
        </div>
    );
};

LoaderPage.propTypes = {};
LoaderPage.defaultProps = {};


// export default withCookies(LoaderPage);
export default LoaderPage;
