/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const GET_ALL_MODULES = "GET_ALL_MODULES";
export const GET_ALL_MODULES_SUCCESS = "GET_ALL_MODULES_SUCCESS";
export const GET_ALL_MODULES_FAILED = "GET_ALL_MODULES_FAILED";

export const GET_ALL_CONFIGURATION = "GET_ALL_CONFIGURATION";
export const GET_ALL_CONFIGURATION_SUCCESS = "GET_ALL_CONFIGURATION_SUCCESS";
export const GET_ALL_CONFIGURATION_FAILED = "GET_ALL_CONFIGURATION_FAILED";

export const GET_FIELDS = "GET_FIELDS";
export const GET_MODULE_BY_ID = "GET_MODULE_BY_ID";
export const GET_MODULE_BY_ID_SUCCESS = "GET_MODULE_BY_ID_SUCCESS";
export const GET_MODULE_BY_ID_FAILED = "GET_MODULE_BY_ID_FAILED";
export const DELETE_ERROR = "DELETE_ERROR";

export const GET_CONFIGURATIONS_MODULE_BY_ID =
  "GET_CONFIGURATIONS_MODULE_BY_ID";
export const GET_CONFIGURATIONS_MODULE_BY_ID_SUCCESS =
  "GET_CONFIGURATIONS_MODULE_BY_ID_SUCCESS";
export const GET_CONFIGURATIONS_MODULE_BY_ID_FAILED =
  "GET_CONFIGURATIONS_MODULE_BY_ID_FAILED";

export const GET_SESSIONS_MODULE_BY_ID = "GET_SESSIONS_MODULE_BY_ID";
export const GET_SESSIONS_MODULE_BY_ID_SUCCESS =
  "GET_SESSIONS_MODULE_BY_ID_SUCCESS";
export const GET_SESSIONS_MODULE_BY_ID_FAILED =
  "GET_SESSIONS_MODULE_BY_ID_FAILED";

export const SUBSCRIBE_TO_TRAININGSESSION = "SUBSCRIBE_TO_TRAININGSESSION";
export const SUBSCRIBE_TO_TRAININGSESSION_SUCCESS =
  "SUBSCRIBE_TO_TRAININGSESSION_SUCCESS";
export const SUBSCRIBE_TO_TRAININGSESSION_FAILED =
  "SUBSCRIBE_TO_TRAININGSESSION_FAILED";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_FAILED = "GET_ALL_CATEGORIES_FAILED";

export const GET_ALL_QUIZ = "GET_ALL_QUIZ";
export const GET_ALL_QUIZ_SUCCESS = "GET_ALL_QUIZ_SUCCESS";
export const GET_ALL_QUIZ_FAILED = "GET_ALL_QUIZ_FAILED";
export const GET_QUIZ = "GET_QUIZ";
export const GET_QUIZ_SUCCESS = "GET_QUIZ_SUCCESS";
export const GET_QUIZ_FAILED = "GET_QUIZ_FAILED";

export const SAVE_QUIZ = "SAVE_QUIZ";
export const SAVE_QUIZ_SUCCESS = "SAVE_QUIZ_SUCCESS";
export const SAVE_QUIZ_FAILED = "SAVE_QUIZ_FAILED";

export const GET_NOTIONS_MODULE_BY_ID = "GET_NOTIONS_MODULE_BY_ID";
export const GET_NOTIONS_MODULE_BY_ID_SUCCESS =
  "GET_NOTIONS_MODULE_BY_ID_SUCCESS";
export const GET_NOTIONS_MODULE_BY_ID_FAILED =
  "GET_NOTIONS_MODULE_BY_ID_FAILED";

export const ADD_SESSION_MODULE = "ADD_SESSION_MODULE";
export const ADD_SESSION_MODULE_SUCCESS = "ADD_SESSION_MODULE_SUCCESS";
export const ADD_SESSION_MODULE_FAILED = "ADD_SESSION_MODULE_FAILED";

export const DELETE_SESSION_MODULE = "DELETE_SESSION_MODULE";
export const DELETE_SESSION_MODULE_SUCCESS = "DELETE_SESSION_MODULE_SUCCESS";
export const DELETE_SESSION_MODULE_FAILED = "DELETE_SESSION_MODULE_FAILED";

export const EDIT_SESSION_MODULE = "EDIT_SESSION_MODULE";
export const EDIT_SESSION_MODULE_SUCCESS = "EDIT_SESSION_MODULE_SUCCESS";
export const EDIT_SESSION_MODULE_FAILED = "EDIT_SESSION_MODULE_FAILED";

export const ARCHIVE_SESSION_MODULE_FAILED = "ARCHIVE_SESSION_MODULE_FAILED";
export const ARCHIVE_SESSION_MODULE_SUCCESS = "ARCHIVE_SESSION_MODULE_SUCCESS";
export const ARCHIVE_SESSION_MODULE = "ARCHIVE_SESSION_MODULE";

export const CREATE_CONFIGURATION = "CREATE_CONFIGURATION";
export const CREATE_CONFIGURATION_FAILED = "CREATE_CONFIGURATION_FAILED";
export const CREATE_CONFIGURATION_SUCCESS = "CREATE_CONFIGURATION_SUCCESS";

export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";
export const DELETE_CONFIGURATION_FAILED = "DELETE_CONFIGURATION_FAILED";
export const DELETE_CONFIGURATION_SUCCESS = "DELETE_CONFIGURATION_SUCCESS";

export const EDIT_CONFIGURATION = "EDIT_CONFIGURATION";
export const EDIT_CONFIGURATION_FAILED = "EDIT_CONFIGURATION_FAILED";
export const EDIT_CONFIGURATION_SUCCESS = "EDIT_CONFIGURATION_SUCCESS";

export const DUPLICATE_CONFIGURATION = "DUPLICATE_CONFIGURATION";
export const DUPLICATE_CONFIGURATION_FAILED = "DUPLICATE_CONFIGURATION_FAILED";
export const DUPLICATE_CONFIGURATION_SUCCESS =
  "DUPLICATE_CONFIGURATION_SUCCESS";

export const ADD_NOTIONS_MODULE = "ADD_NOTIONS_MODULE";
export const ADD_NOTIONS_MODULE_FAILED = "ADD_NOTIONS_MODULE_FAILED";
export const ADD_NOTIONS_MODULE_SUCCESS = "ADD_NOTIONS_MODULE_SUCCESS";

export const EDIT_NOTIONS_MODULE = "EDIT_NOTIONS_MODULE";
export const EDIT_NOTIONS_MODULE_FAILED = "EDIT_NOTIONS_MODULE_FAILED";
export const EDIT_NOTIONS_MODULE_SUCCESS = "EDIT_NOTIONS_MODULE_SUCCESS";

export const DELETE_NOTIONS_MODULE = "DELETE_NOTIONS_MODULE";
export const DELETE_NOTIONS_MODULE_SUCCESS = "DELETE_NOTIONS_MODULE_SUCCESS";
export const DELETE_NOTIONS_MODULE_FAILED = "DELETE_NOTIONS_MODULE_FAILED";

export const ORDER_NOTIONS_MODULE = "ORDER_NOTIONS_MODULE";
export const ORDER_NOTIONS_MODULE_SUCCESS = "ORDER_NOTIONS_MODULE_SUCCESS";
export const ORDER_NOTIONS_MODULE_FAILED = "ORDER_NOTIONS_MODULE_FAILED";

export const GET_ALL_QUIZ_MODERATOR = "GET_ALL_QUIZ_MODERATOR";
export const GET_ALL_QUIZ_MODERATOR_SUCCESS = "GET_ALL_QUIZ_MODERATOR_SUCCESS";
export const GET_ALL_QUIZ_MODERATOR_FAILED = "GET_ALL_QUIZ_MODERATOR_FAILED";

export const ADD_QUIZ_MODERATOR = "ADD_QUIZ_MODERATOR";
export const ADD_QUIZ_MODERATOR_SUCCESS = "ADD_QUIZ_MODERATOR_SUCCESS";
export const ADD_QUIZ_MODERATOR_FAILED = "ADD_QUIZ_MODERATOR_FAILED";

export const DELETE_QUIZ_MODERATOR = "DELETE_QUIZ_MODERATOR";
export const DELETE_QUIZ_MODERATOR_SUCCESS = "DELETE_QUIZ_MODERATOR_SUCCESS";
export const DELETE_QUIZ_MODERATOR_FAILED = "DELETE_QUIZ_MODERATOR_FAILED";

export const EDIT_QUIZ_MODERATOR = "EDIT_QUIZ_MODERATOR";
export const EDIT_QUIZ_MODERATOR_SUCCESS = "EDIT_QUIZ_MODERATOR_SUCCESS";
export const EDIT_QUIZ_MODERATOR_FAILED = "EDIT_QUIZ_MODERATOR_FAILED";

export const ADD_QUESTION_QUIZ_MODERATOR = "ADD_QUESTION_QUIZ_MODERATOR";
export const ADD_QUESTION_QUIZ_MODERATOR_SUCCESS =
  "ADD_QUESTION_QUIZ_MODERATOR_SUCCESS";
export const ADD_QUESTION_QUIZ_MODERATOR_FAILED =
  "ADD_QUESTION_QUIZ_MODERATOR_FAILED";

export const DELETE_QUESTION_QUIZ_MODERATOR = "DELETE_QUESTION_QUIZ_MODERATOR";
export const DELETE_QUESTION_QUIZ_MODERATOR_SUCCESS =
  "DELETE_QUESTION_QUIZ_MODERATOR_SUCCESS";
export const DELETE_QUESTION_QUIZ_MODERATOR_FAILED =
  "DELETE_QUESTION_QUIZ_MODERATOR_FAILED";

export const EDIT_QUESTION_QUIZ_MODERATOR = "EDIT_QUESTION_QUIZ_MODERATOR";
export const EDIT_QUESTION_QUIZ_MODERATOR_SUCCESS =
  "EDIT_QUESTION_QUIZ_MODERATOR_SUCCESS";
export const EDIT_QUESTION_QUIZ_MODERATOR_FAILED =
  "EDIT_QUESTION_QUIZ_MODERATOR_FAILED";

export const GET_PARTICIPANTS_MODERATOR = "GET_PARTICIPANTS_MODERATOR";
export const GET_PARTICIPANTS_MODERATOR_SUCCESS =
  "GET_PARTICIPANTS_MODERATOR_SUCCESS";
export const GET_PARTICIPANTS_MODERATOR_FAILED =
  "GET_PARTICIPANTS_MODERATOR_FAILED";

export const CHANGE_STATUS_PARTICIPANT_MODERATOR =
  "CHANGE_STATUS_PARTICIPANT_MODERATOR";
export const CHANGE_STATUS_PARTICIPANT_MODERATOR_SUCCESS =
  "CHANGE_STATUS_PARTICIPANT_MODERATOR_SUCCESS";
export const CHANGE_STATUS_PARTICIPANT_MODERATOR_FAILED =
  "CHANGE_STATUS_PARTICIPANT_MODERATOR_FAILED";

export const GET_QUIZ_STATISTICS_MODERATOR = "GET_QUIZ_STATISTICS_MODERATOR";
export const GET_QUIZ_STATISTICS_MODERATOR_SUCCESS =
  "GET_QUIZ_STATISTICS_MODERATOR_SUCCESS";
export const GET_QUIZ_STATISTICS_MODERATOR_FAILED =
  "GET_QUIZ_STATISTICS_MODERATOR_FAILED";

export const SELECT_QUIZ = "SELECT_QUIZ";
export const SELECT_CONFIGURATION = "SELECT_CONFIGURATION";
export const SELECT_SESSION = "SELECT_SESSION";
export const SELECT_PARTICIPANTS_MODERATOR = "SELECT_PARTICIPANTS_MODERATOR";
export const SELECT_QUIZ_STATISTICS_MODERATOR =
  "SELECT_QUIZ_STATISTICS_MODERATOR";

export const INIT_SUCCESS = "INIT_SUCCESS";
export const INIT_SUCCESS_NOTION = "INIT_SUCCESS_NOTION";
export const INIT_SUCCESS_QUIZ = "INIT_SUCCESS_QUIZ";
export const INIT_ERROR_NOTION = "INIT_ERROR_NOTION";
export const INIT_SUCCESS_STUDENT = "INIT_SUCCESS_STUDENT";

export const GET_STRAT_EDGE_CONFIGURATION = "GET_STRAT_EDGE_CONFIGURATION";
export const GET_STRAT_EDGE_CONFIGURATION_SUCCESS =
  "GET_STRAT_EDGE_CONFIGURATION_SUCCESS";
export const GET_STRAT_EDGE_CONFIGURATION_FAILED =
  "GET_STRAT_EDGE_CONFIGURATION_FAILED";

export const GET_STRAT_EDGE_CONFIGURATION_PARTICIPANT =
  "GET_STRAT_EDGE_CONFIGURATION_PARTICIPANT";
export const GET_STRAT_EDGE_CONFIGURATION_PARTICIPANT_SUCCESS =
  "GET_STRAT_EDGE_CONFIGURATION_PARTICIPANT_SUCCESS";
export const GET_STRAT_EDGE_CONFIGURATION_PARTICIPANT_FAILED =
  "GET_STRAT_EDGE_CONFIGURATION_PARTICIPANT_FAILED";

export const GET_STRAT_EDGE_RESULT = "GET_STRAT_EDGE_RESULT";
export const GET_STRAT_EDGE_RESULT_SUCCESS = "GET_STRAT_EDGE_RESULT_SUCCESS";
export const GET_STRAT_EDGE_RESULT_FAILED = "GET_STRAT_EDGE_RESULT_FAILED";

export const GET_STRAT_EDGE_RESULT_PARTICIPANT =
  "GET_STRAT_EDGE_RESULT_PARTICIPANT";
export const GET_STRAT_EDGE_RESULT_PARTICIPANT_SUCCESS =
  "GET_STRAT_EDGE_RESULT_PARTICIPANT_SUCCESS";
export const GET_STRAT_EDGE_RESULT_PARTICIPANT_FAILED =
  "GET_STRAT_EDGE_RESULT_PARTICIPANT_FAILED";

export const START_OR_STOP_ROUND = "START_OR_STOP_ROUND";
export const START_OR_STOP_ROUND_SUCCESS = "START_OR_STOP_ROUND_SUCCESS";
export const START_OR_STOP_ROUND_FAILED = "START_OR_STOP_ROUND_FAILED";

export const UPDATE_STRAT_EDGE_PRODUCT = "UPDATE_STRAT_EDGE_PRODUCT";
export const UPDATE_STRAT_EDGE_PRODUCT_SUCCESS =
  "UPDATE_STRAT_EDGE_PRODUCT_SUCCESS";
export const UPDATE_STRAT_EDGE_PRODUCT_FAILED =
  "UPDATE_STRAT_EDGE_PRODUCT_FAILED";

export const UPDATE_STRAT_EDGE_COMPETITOR = "UPDATE_STRAT_EDGE_COMPETITOR";
export const UPDATE_STRAT_EDGE_COMPETITOR_SUCCESS =
  "UPDATE_STRAT_EDGE_COMPETITOR_SUCCESS";
export const UPDATE_STRAT_EDGE_COMPETITOR_FAILED =
  "UPDATE_STRAT_EDGE_COMPETITOR_FAILED";

export const UPDATE_STRAT_EDGE_MARKET = "UPDATE_STRAT_EDGE_MARKET";
export const UPDATE_STRAT_EDGE_MARKET_SUCCESS =
  "UPDATE_STRAT_EDGE_MARKET_SUCCESS";
export const UPDATE_STRAT_EDGE_MARKET_FAILED =
  "UPDATE_STRAT_EDGE_MARKET_FAILED";

export const UPDATE_STRAT_EDGE_STRATEGIC_DECISION =
  "UPDATE_STRAT_EDGE_STRATEGIC_DECISION";
export const UPDATE_STRAT_EDGE_STRATEGIC_DECISION_SUCCESS =
  "UPDATE_STRAT_EDGE_STRATEGIC_DECISION_SUCCESS";
export const UPDATE_STRAT_EDGE_STRATEGIC_DECISION_FAILED =
  "UPDATE_STRAT_EDGE_STRATEGIC_DECISION_FAILED";

export const INIT_SIMULATION = "INIT_SIMULATION";
export const INIT_SIMULATION_SUCCESS = "INIT_SIMULATION_SUCCESS";
export const INIT_SIMULATION_FAILED = "INIT_SIMULATION_FAILED";

export const RUN_SIMULATION = "RUN_SIMULATION";
export const RUN_SIMULATION_SUCCESS = "RUN_SIMULATION_SUCCESS";
export const RUN_SIMULATION_FAILED = "RUN_SIMULATION_FAILED";

export const SAVE_SCENARIO = "SAVE_SCENARIO";
export const SAVE_SCENARIO_SUCCESS = "SAVE_SCENARIO_SUCCESS";
export const SAVE_SCENARIO_FAILED = "SAVE_SCENARIO_FAILED";

export const GET_SCENARIOS = "GET_SCENARIOS";
export const GET_SCENARIOS_SUCCESS = "GET_SCENARIOS_SUCCESS";
export const GET_SCENARIOS_FAILED = "GET_SCENARIOS_FAILED";

export const DELETE_SCENARIO = "DELETE_SCENARIO";
export const DELETE_SCENARIO_SUCCESS = "DELETE_SCENARIO_SUCCESS";
export const DELETE_SCENARIO_FAILED = "DELETE_SCENARIO_FAILED";

export const SEND_DECISION = "SEND_DECISION";
export const SEND_DECISION_SUCCESS = "SEND_DECISION_SUCCESS";
export const SEND_DECISION_FAILED = "SEND_DECISION_FAILED";

export const ADD_DECISION_SIMULATION = "ADD_DECISION_SIMULATION";
export const INIT_SAVE_SCENARIO_SUCCESS = "INIT_SAVE_SCENARIO_SUCCESS";
export const INIT_DELETE_SCENARIO_SUCCESS = "INIT_DELETE_SCENARIO_SUCCESS";
export const INIT_SEND_DECISION_SUCCESS = "INIT_SEND_DECISION_SUCCESS";
export const INIT_STRAT_EDGE = "INIT_STRAT_EDGE";
export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAILED = "GET_TEAMS_FAILED";

export const ASSIGNMENT_TEAMS = "ASSIGNMENT_TEAMS";
export const ASSIGNMENT_TEAMS_SUCCESS = "ASSIGNMENT_TEAMS_SUCCESS";
export const ASSIGNMENT_TEAMS_FAILED = "ASSIGNMENT_TEAMS_FAILED";

export const CHOOSE_DECISION = "CHOOSE_DECISION";
export const CHOOSE_DECISION_SUCCESS = "CHOOSE_DECISION_SUCCESS";
export const CHOOSE_DECISION_FAILED = "CHOOSE_DECISION_FAILED";
export const START_SIMULATION = "START_SIMULATION";

export const GET_STRAT_EDGE_GAME_SESSION_CONFIGURATION =
  "GET_STRAT_EDGE_GAME_SESSION_CONFIGURATION";

export const DAY7_ON_CHANGE_CAS_OPTION = "DAY7_ON_CHANGE_CAS_OPTION";
export const DAY2_ON_CHANGE_CAS_OPTION = "DAY2_ON_CHANGE_CAS_OPTION";
export const DAY2_VALID_DAY = "DAY2_VALID_DAY";
export const DAY2_VALID_DAY_SUCCESS = "DAY2_VALID_DAY_SUCCESS";
export const DAY2_VALID_DAY_FAILED = "DAY2_VALID_DAY_FAILED";
export const DAY7_VALID_CAS = "DAY7_VALID_CAS";
export const DAY7_VALID_CAS_SUCCESS = "DAY7_VALID_CAS_SUCCESS";
export const DAY7_VALID_CAS_FAILED = "DAY7_VALID_CAS_FAILED";

export const DAY10_ON_CHANGE_PART1 = "DAY10_ON_CHANGE_PART1";
export const DAY10_ON_CHANGE_PART2 = "DAY10_ON_CHANGE_PART2";
export const DAY10_RESET_PART2 = "DAY10_RESET_PART2";

export const GET_CENTER_INFO = "GET_CENTER_INFO";
export const GET_CENTER_INFO_SUCCESS = "GET_CENTER_INFO_SUCCESS";
export const GET_CENTER_INFO_FAILED = "GET_CENTER_INFO_FAILED";

export const UPDATE_CENTER_INFO = "UPDATE_CENTER_INFO";
export const UPDATE_CENTER_INFO_SUCCESS = "UPDATE_CENTER_INFO_SUCCESS";
export const UPDATE_CENTER_INFO_FAILED = "UPDATE_CENTER_INFO_FAILED";

export const DAY3_UPDATE_DECISIONS = "DAY3_UPDATE_DECISIONS";
export const DAY3_VALIDATIONS = "DAY3_VALIDATIONS";
export const DAY3_VALIDATIONS_SUCCESS = "DAY3_VALIDATIONS_SUCCESS";
export const DAY3_VALIDATIONS_FAILED = "DAY3_VALIDATIONS_FAILED";

export const DAY4_PART2_ONDRAG = "DAY4_PART2_ONDRAG";
export const DAY4_PART1_ONCHANGE_IS_SELECTED =
  "DAY4_PART1_ONCHANGE_IS_SELECTED";
export const DAY4_VALIDATION = "DAY4_VALIDATION";
export const DAY4_VALIDATION_SUCCESS = "DAY4_VALIDATION_SUCCESS";
export const DAY4_VALIDATION_FAILED = "DAY4_VALIDATION_FAILED";

export const DAY5_ONSELECT_CAT = "DAY5_ONSELECT_CAT";
export const DAY5_ONCHANGE_CAT0_RANK1 = "DAY5_ONCHANGE_CAT0_RANK1";
export const DAY5_ONCHANGE_CAT0_RANK2 = "DAY5_ONCHANGE_CAT0_RANK2";
export const DAY5_ONCHANGE_CAT0_RANK3 = "DAY5_ONCHANGE_CAT0_RANK3";
export const DAY5_ONCHANGE_CAT0_RANK4 = "DAY5_ONCHANGE_CAT0_RANK4";

export const DAY5_ONCHANGE_CAT1_LEFT_DECISIONS1 =
  "DAY5_ONCHANGE_CAT1_LEFT_DECISIONS1";
export const DAY5_ONCHANGE_CAT1_LEFT_DECISIONS2 =
  "DAY5_ONCHANGE_CAT1_LEFT_DECISIONS2";
export const DAY5_ONCHANGE_CAT1_LEFT_DECISIONS3 =
  "DAY5_ONCHANGE_CAT1_LEFT_DECISIONS3";
export const DAY5_ONCHANGE_CAT1_LEFT_DECISIONS4 =
  "DAY5_ONCHANGE_CAT1_LEFT_DECISIONS4";
export const DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS1 =
  "DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS1";
export const DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS2 =
  "DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS2";
export const DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS3 =
  "DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS3";
export const DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS4 =
  "DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS4";

export const DAY5_ONCHANGE_CAT2_LEFT_DECISIONS1 =
  "DAY5_ONCHANGE_CAT2_LEFT_DECISIONS1";
export const DAY5_ONCHANGE_CAT2_LEFT_DECISIONS2 =
  "DAY5_ONCHANGE_CAT2_LEFT_DECISIONS2";
export const DAY5_ONCHANGE_CAT2_LEFT_DECISIONS3 =
  "DAY5_ONCHANGE_CAT2_LEFT_DECISIONS3";
export const DAY5_ONCHANGE_CAT2_LEFT_DECISIONS4 =
  "DAY5_ONCHANGE_CAT2_LEFT_DECISIONS4";
export const DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS1 =
  "DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS1";
export const DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS2 =
  "DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS2";
export const DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS3 =
  "DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS3";
export const DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS4 =
  "DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS4";

export const DAY5_ONCHANGE_CAT2_RANK1 = "DAY5_ONCHANGE_CAT2_RANK1";
export const DAY5_ONCHANGE_CAT2_RANK2 = "DAY5_ONCHANGE_CAT2_RANK2";
export const DAY5_ONCHANGE_CAT2_RANK3 = "DAY5_ONCHANGE_CAT2_RANK3";
export const DAY5_ONCHANGE_CAT2_RANK4 = "DAY5_ONCHANGE_CAT2_RANK4";

export const DAY5_VALIDATION = "DAY5_VALIDATION";
export const DAY5_VALIDATION_FAILED = "DAY5_VALIDATION_FAILED";
export const DAY5_VALIDATION_SUCCESS = "DAY5_VALIDATION_SUCCESS";

export const Day6_ONCHANGE_CAT2 = "Day6_ONCHANGE_CAT2";
export const Day6_ONCHANGE_CAT3 = "Day6_ONCHANGE_CAT3";
export const Day6_ONCHANGE_CAT1 = "Day6_ONCHANGE_CAT1";

export const Day6_VALIDATION = "Day6_VALIDATION";
export const Day6_VALIDATION_SUCCESS = "Day6_VALIDATION_SUCCESS";
export const Day6_VALIDATION_FAILED = "Day6_VALIDATION_FAILED";

export const DAY8_VALIDATION = "DAY8_VALIDATION";
export const DAY8_VALIDATION_FAILED = "DAY8_VALIDATION_FAILED";
export const DAY8_VALIDATION_SUCCESS = "DAY8_VALIDATION_SUCCESS";

export const DAY2_GET_DETAIL = "DAY2_GET_DETAIL";
export const DAY2_GET_DETAIL_FAILED = "DAY2_GET_DETAIL_FAILED";
export const DAY2_GET_DETAIL_SUCCESS = "DAY2_GET_DETAIL_SUCCESS";

export const DAY3_GET_DETAIL = "DAY3_GET_DETAIL";
export const DAY3_GET_DETAIL_FAILED = "DAY3_GET_DETAIL_FAILED";
export const DAY3_GET_DETAIL_SUCCESS = "DAY3_GET_DETAIL_SUCCESS";

export const DAY4_GET_DETAIL = "DAY4_GET_DETAIL";
export const DAY4_GET_DETAIL_SUCCESS = "DAY4_GET_DETAIL_SUCCESS";
export const DAY4_GET_DETAIL_FAILED = "DAY4_GET_DETAIL_FAILED";

export const DAY5_GET_DETAIL = "DAY5_GET_DETAIL";
export const PH5_DAY5_GET_DETAIL = "PH5_DAY5_GET_DETAIL";
export const DAY5_GET_DETAIL_SUCCESS = "DAY5_GET_DETAIL_SUCCESS";
export const PH5_DAY5_GET_DETAIL_SUCCESS = "PH5_DAY5_GET_DETAIL_SUCCESS";
export const DAY5_GET_DETAIL_FAILED = "DAY5_GET_DETAIL_FAILED";
export const PH5_DAY5_GET_DETAIL_FAILED = "PH5_DAY5_GET_DETAIL_FAILED";

export const Day6_GET_DETAIL = "Day6_GET_DETAIL";
export const Day6_GET_DETAIL_SUCCESS = "Day6_GET_DETAIL_SUCCESS";
export const Day6_GET_DETAIL_FAILED = "Day6_GET_DETAIL_FAILED";

export const GET_SCORE = "GET_SCORE";
export const GET_SCORE_SUCCESS = "GET_SCORE_SUCCESS";
export const GET_SCORE_FAILED = "GET_SCORE_FAILED";

export const GET_SCORE_GLOBAL = "GET_SCORE_GLOBAL";
export const GET_SCORE_GLOBAL_SUCCESS = "GET_SCORE_GLOBAL_SUCCESS";
export const GET_SCORE_GLOBAL_FAILED = "GET_SCORE_GLOBAL_FAILED";

export const CLOSE_DAY = "CLOSE_DAY";
export const CLOSE_DAY_CLEAR = "CLOSE_DAY_CLEAR";

export const DAY10_VALIDATIONS = "DAY10_VALIDATIONS";
export const DAY10_VALIDATIONS_SUCCESS = "DAY10_VALIDATIONS_SUCCESS";
export const DAY10_VALIDATIONS_FAILED = "DAY10_VALIDATIONS_FAILED";

export const Day10_GET_DETAIL = "Day10_GET_DETAIL";
export const Day10_GET_DETAIL_SUCCESS = "Day10_GET_DETAIL_SUCCESS";
export const Day10_GET_DETAIL_FAILED = "Day10_GET_DETAIL_FAILED";

export const GET_INTRA_DAY_ASSET_DATA = "GET_INTRA_DAY_ASSET_DATA";
export const GET_INTRA_DAY_ASSET_DATA_SUCCESS = "GET_INTRA_DAY_ASSET_DATA_SUCCESS";
export const GET_INTRA_DAY_ASSET_DATA_FAILED = "GET_INTRA_DAY_ASSET_DATA_FAILED";

export const GET_INTRA_DAY_MARKET_DATA = "GET_INTRA_DAY_MARKET_DATA";
export const GET_INTRA_DAY_MARKET_DATA_SUCCESS = "GET_INTRA_DAY_MARKET_DATA_SUCCESS";
export const GET_INTRA_DAY_MARKET_DATA_FAILED = "GET_INTRA_DAY_MARKET_DATA_FAILED";

export const DAY7_VALID_DAY = "DAY7_VALID_DAY";
export const DAY7_VALID_DAY_SUCCESS = "DAY7_VALID_DAY_SUCCESS";
export const DAY7_VALID_DAY_FAILED = "DAY7_VALID_DAY_FAILED";

export const DAY7_GET_DETAIL = "DAY7_GET_DETAIL";
export const DAY7_GET_DETAIL_SUCCESS = "DAY7_GET_DETAIL_SUCCESS";
export const DAY7_GET_DETAIL_FAILED = "DAY7_GET_DETAIL_FAILED";

export const GET_SCORE_GLOBAL_MODERATOR = "GET_SCORE_GLOBAL_MODERATOR";
export const GET_SCORE_GLOBAL_MODERATOR_SUCCESS = "GET_SCORE_GLOBAL_MODERATOR_SUCCESS";
export const GET_SCORE_GLOBAL_MODERATOR_FAILED = "GET_SCORE_GLOBAL_MODERATOR_FAILED";

export const GET_INITIAL_DATA = "GET_INITIAL_DATA";
export const GET_INITIAL_DATA_SUCCESS = "GET_INITIAL_DATA_SUCCESS";
export const GET_INITIAL_DATA_FAILED = "GET_INITIAL_DATA_FAILED";

export const GET_RANKINGS = "GET_RANKINGS";
export const GET_RANKINGS_SUCCESS = "GET_RANKINGS_SUCCESS";
export const GET_RANKINGS_FAILED = "GET_RANKINGS_FAILED";

export const GET_MARKET_VIEWS_DATA = "GET_MARKET_VIEWS_DATA";
export const GET_MARKET_VIEWS_DATA_SUCCESS = "GET_MARKET_VIEWS_DATA_SUCCESS";
export const GET_MARKET_VIEWS_DATA_FAILED = "GET_MARKET_VIEWS_DATA_FAILED";
export const SET_MARKET_VIEWS_CHART_COLUMN_DATA = "SET_MARKET_VIEWS_CHART_COLUMN_DATA";
export const SET_MARKET_VIEWS_CHART_LINE_DATA = "SET_MARKET_VIEWS_CHART_LINE_DATA";
export const SET_MARKET_VIEWS_PERFORMANCE_ARRAY = "SET_MARKET_VIEWS_PERFORMANCE_ARRAY";

export const GET_HISTORICAL_DATA = "GET_HISTORICAL_DATA";
export const GET_HISTORICAL_DATA_SUCCESS = "GET_HISTORICAL_DATA_SUCCESS";
export const GET_HISTORICAL_DATA_FAILED = "GET_HISTORICAL_DATA_FAILED";
export const SET_ASSET_CHART_COLUMN_DATA = "SET_ASSET__CHART_COLUMN_DATA";
export const SET_ASSET_CHART_LINE_DATA = "SET_ASSET_CHART_LINE_DATA";


export const GET_ORDERS_DATA = "GET_ORDERS_DATA";
export const GET_ORDERS_DATA_SUCCESS = "GET_ORDERS_DATA_SUCCESS";
export const GET_ORDERS_DATA_FAILED = "GET_ORDERS_DATA_FAILED";


export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILED = "ADD_ORDER_FAILED";

export const GET_LAST_PERFORMANCES_DATA = "GET_LAST_PERFORMANCES_DATA";
export const GET_LAST_PERFORMANCES_DATA_SUCCESS = "GET_LAST_PERFORMANCES_DATA_SUCCESS";
export const GET_LAST_PERFORMANCES_DATA_FAILED = "GET_LAST_PERFORMANCES_DATA_FAILED";
export const GET_LAST_PERFORMANCES_DATA_API_SUCCESS = "GET_LAST_PERFORMANCES_DATA_API_SUCCESS";


export const GET_LAST_POSITIONS_DATA = "GET_LAST_POSITIONS_DATA";
export const GET_LAST_POSITIONS_DATA_SUCCESS = "GET_LAST_POSITIONS_DATA_SUCCESS";
export const GET_LAST_POSITIONS_DATA_FAILED = "GET_LAST_POSITIONS_DATA_FAILED";
export const GET_LAST_POSITIONS_DATA_API_SUCCESS = "GET_LAST_POSITIONS_DATA_API_SUCCESS";

export const GET_INDICATORS = "GET_INDICATORS";
export const GET_INDICATORS_SUCCESS = "GET_INDICATORS_SUCCESS";
export const GET_INDICATORS_FAILED = "GET_INDICATORS_FAILED";

export const GET_GLOBAL_STATISTIC = "GET_GLOBAL_STATISTIC";
export const GET_GLOBAL_STATISTIC_SUCCESS = "GET_GLOBAL_STATISTIC_SUCCESS";
export const GET_GLOBAL_STATISTIC_FAILED = "GET_GLOBAL_STATISTIC_FAILED";
export const GET_GLOBAL_RISK = "GET_GLOBAL_RISK";
export const GET_GLOBAL_RISK_SUCCESS = "GET_GLOBAL_RISK_SUCCESS";
export const GET_GLOBAL_RISK_FAILED = "GET_GLOBAL_RISK_FAILED";
export const SET_VARIATION_VOLATILITY_AND_BETA = "SET_VARIATION_VOLATILITY_AND_BETA";
export const SET_VOLATILITY_VALUE_RISK = "SET_VOLATILITY_VALUE_RISK";
export const SET_BETA_VALUE_RISK = "SET_BETA_VALUE_RISK";
export const GET_ANALYSE_RISK = "GET_ANALYSE_RISK";

export const SET_ASSET_CLASS_CONCENTRATION = "SET_ASSET_CLASS_CONCENTRATION";
export const SET_ASSET_CONCENTRATION = "SET_ASSET_CONCENTRATION";
export const SET_SECTOR_CONCENTRATION = "SET_SECTOR_CONCENTRATION";
export const SET_RANKING_CHART_DATA = "SET_RANKING_CHART_DATA";
export const SET_YIELDS_AND_PERFS = "SET_YIELDS_AND_PERFS";
export const SET_COMPARISON_PTF_BENCH = "SET_COMPARISON_PTF_BENCH";
export const SET_COMPARISON_YIELD = "SET_COMPARISON_YIELD";
export const GET_SUCCESS_ORDERS_DATA_SUCCESS = "GET_SUCCESS_ORDERS_DATA_SUCCESS";


export const GET_CENTER_INFO5 = "GET_CENTER_INFO5";
export const GET_CENTER_INFO5_SUCCESS = "GET_CENTER_INFO5_SUCCESS";
export const GET_CENTER_INFO5_FAILED = "GET_CENTER_INFO5_FAILED";
export const UPDATE_CENTER_INFO5 = "UPDATE_CENTER_INFO5";
export const UPDATE_CENTER_INFO5_SUCCESS = "UPDATE_CENTER_INFO5_SUCCESS";
export const UPDATE_CENTER_INFO5_FAILED = "UPDATE_CENTER_INFO5_FAILED";
export const GET_SCORE5 = "GET_SCORE5";
export const GET_SCORE5_SUCCESS = "GET_SCORE5_SUCCESS";
export const GET_SCORE5_FAILED = "GET_SCORE5_FAILED";
export const GET_SCORE_GLOBAL5 = "GET_SCORE_GLOBAL5";
export const GET_SCORE_GLOBAL5_SUCCESS = "GET_SCORE_GLOBAL5_SUCCESS";
export const GET_SCORE_GLOBAL5_FAILED = "GET_SCORE_GLOBAL5_FAILED";
export const CLOSE_DAY5 = "CLOSE_DAY5";
export const CLOSE_DAY_CLEAR5 = "CLOSE_DAY_CLEAR5";
export const GET_SCORE_GLOBAL_MODERATOR5 = "GET_SCORE_GLOBAL_MODERATOR5";
export const GET_SCORE_GLOBAL_MODERATOR5_SUCCESS = "GET_SCORE_GLOBAL_MODERATOR5_SUCCESS";
export const GET_SCORE_GLOBAL_MODERATOR5_FAILED = "GET_SCORE_GLOBAL_MODERATOR5_FAILED";


export const DAY1_PART2_ONDRAG = "DAY1_PART2_ONDRAG";
export const DAY1_PART1_ONCHANGE_IS_SELECTED = "DAY1_PART1_ONCHANGE_IS_SELECTED";
export const PH5_DAY5_PART1_ONCHANGE_IS_SELECTED = "PH5_DAY5_PART1_ONCHANGE_IS_SELECTED";
export const DAY1_VALIDATION = "DAY1_VALIDATION";
export const DAY1_VALIDATION_SUCCESS = "DAY1_VALIDATION_SUCCESS";
export const DAY1_VALIDATION_FAILED = "DAY1_VALIDATION_FAILED";
export const DAY1_GET_DETAIL = "DAY1_GET_DETAIL";
export const DAY1_GET_DETAIL_SUCCESS = "DAY1_GET_DETAIL_SUCCESS";
export const DAY1_GET_DETAIL_FAILED = "DAY1_GET_DETAIL_FAILED";

export const DAY1_STEP_2_UPDATE_DECISIONS = "DAY1_STEP_2_UPDATE_DECISIONS";
export const DAY1_STEP_2_VALIDATIONS = "DAY1_STEP_2_VALIDATIONS";
export const DAY1_STEP_2_VALIDATIONS_SUCCESS = "DAY1_STEP_2_VALIDATIONS_SUCCESS";
export const DAY1_STEP_2_VALIDATIONS_FAILED = "DAY1_STEP_2_VALIDATIONS_FAILED";
export const DAY1_STEP_2_GET_DETAIL = "DAY1_STEP_2_GET_DETAIL";
export const DAY1_STEP_2_GET_DETAIL_SUCCESS = "DAY1_STEP_2_GET_DETAIL_SUCCESS";
export const DAY1_STEP_2_GET_DETAIL_FAILED = "DAY1_STEP_2_GET_DETAIL_FAILED";

export const PVPHA_Day3_ONCHANGE_CAT2 = "PVPHA_Day3_ONCHANGE_CAT2";
export const PVPHA_Day3_ONCHANGE_CAT3 = "PVPHA_Day3_ONCHANGE_CAT3";
export const PVPHA_Day3_ONCHANGE_CAT1 = "PVPHA_Day3_ONCHANGE_CAT1";
export const PVPHA_Day3_VALIDATION = "PVPHA_Day3_VALIDATION";
export const PVPHA_Day3_VALIDATION_SUCCESS = "PVPHA_Day3_VALIDATION_SUCCESS";
export const PVPHA_Day3_VALIDATION_FAILED = "PVPHA_Day3_VALIDATION_FAILED";
export const PVPHA_Day3_GET_DETAIL = "PVPHA_Day3_GET_DETAIL";
export const PVPHA_Day3_GET_DETAIL_SUCCESS = "PVPHA_Day3_GET_DETAIL_SUCCESS";
export const PVPHA_Day3_GET_DETAIL_FAILED = "PVPHA_Day3_GET_DETAIL_FAILED";


export const PH5_DAY2_GET_DETAIL = "PH5_DAY2_GET_DETAIL";
export const PH5_DAY2_GET_DETAIL_FAILED = "PH5_DAY2_GET_DETAIL_FAILED";
export const PH5_DAY2_GET_DETAIL_SUCCESS = "PH5_DAY2_GET_DETAIL_SUCCESS";
export const PH5_DAY2_VALID_DAY = "PH5_DAY2_VALID_DAY";
export const PH5_DAY2_VALID_DAY_FAILED = "PH5_DAY2_VALID_DAY_FAILED";
export const PH5_DAY2_VALID_DAY_SUCCESS = "PH5_DAY2_VALID_DAY_SUCCESS";
export const PH5_DAY2_Part1_ONCHANGE = "PH5_DAY2_Part1_ONCHANGE";
export const PH5_DAY2_PART2_ONDRAG = "PH5_DAY2_PART2_ONDRAG";
export const PH5_DAY2_PART3_ONCHANGE_IS_SELECTED = "PH5_DAY2_PART3_ONCHANGE_IS_SELECTED";
export const PH5_DAY3_PART2_ONCHANGE_IS_SELECTED = "PH5_DAY3_PART2_ONCHANGE_IS_SELECTED";

export const PH5_DAY4_PART1_ONCHANGE_IS_SELECTED = "PH5_DAY4_PART1_ONCHANGE_IS_SELECTED";
export const PH5_DAY4_PART2_ONCHANGE_IS_SELECTED = "PH5_DAY4_PART2_ONCHANGE_IS_SELECTED";
export const PH5_DAY4_PART3_ONCHANGE_IS_SELECTED = "PH5_DAY4_PART3_ONCHANGE_IS_SELECTED";
export const PH5_DAY4_STEP_4_UPDATE_DECISIONS = "PH5_DAY4_STEP_4_UPDATE_DECISIONS";
export const PH5_DAY4_VALIDATION = "PH5_DAY4_VALIDATION";
export const PH5_DAY4_VALIDATION_FAILED = "PH5_DAY4_VALIDATION_FAILED";
export const PH5_DAY4_VALIDATION_SUCCESS = "PH5_DAY4_VALIDATION_SUCCESS";
export const DAY1_PART1_ONCHANGE_IS_SELECTED_RADIO = "DAY1_PART1_ONCHANGE_IS_SELECTED_RADIO";



//  --------- pv challenge  --------------------------------


export const GET_CENTER_INF_PV_CH = "GET_CENTER_INF_PV_CH";
export const GET_CENTER_INF_PV_CH_SUCCESS = "GET_CENTER_INF_PV_CH_SUCCESS";
export const GET_CENTER_INF_PV_CH_FAILED = "GET_CENTER_INF_PV_CH_FAILED";
export const UPDATE_CENTER_INF_PV_CH = "UPDATE_CENTER_INF_PV_CH";
export const UPDATE_CENTER_INF_PV_CH_SUCCESS = "UPDATE_CENTER_INF_PV_CH_SUCCESS";
export const UPDATE_CENTER_INF_PV_CH_FAILED = "UPDATE_CENTER_INF_PV_CH_FAILED";
export const GET_SCORE_PV_CH = "GET_SCORE_PV_CH";
export const GET_SCORE_PV_CH_SUCCESS = "GET_SCORE_PV_CH_SUCCESS";
export const GET_SCORE_PV_CH_FAILED = "GET_SCORE_PV_CH_FAILED";
export const GET_SCORE_GLOBAL_PV_CH = "GET_SCORE_GLOBAL_PV_CH";
export const GET_SCORE_GLOBAL_PV_CH_SUCCESS = "GET_SCORE_GLOBAL_PV_CH_SUCCESS";
export const GET_SCORE_GLOBAL_PV_CH_FAILED = "GET_SCORE_GLOBAL_PV_CH_FAILED";
export const CLOSE_DAY_PV_CH = "CLOSE_DAY_PV_CH";
export const CLOSE_DAY_CLEAR_PV_CH = "CLOSE_DAY_CLEAR_PV_CH";
export const GET_SCORE_GLOBAL_MODERATOR_PV_CH = "GET_SCORE_GLOBAL_MODERATOR_PV_CH";
export const GET_SCORE_GLOBAL_MODERATOR_PV_CH_SUCCESS = "GET_SCORE_GLOBAL_MODERATOR_PV_CH_SUCCESS";
export const GET_SCORE_GLOBAL_MODERATOR_PV_CH_FAILED = "GET_SCORE_GLOBAL_MODERATOR_PV_CH_FAILED";


// -------------------------day1---------------------------------------

export const DAY1_PART1_ONCHANGE_IS_SELECTED_RADIO_PV_CH = "DAY1_PART1_ONCHANGE_IS_SELECTED_RADIO_PV_CH";
export const DAY1_STEP_2_UPDATE_DECISIONS_PV_CH = "DAY1_STEP_2_UPDATE_DECISIONS_PV_CH";
export const DAY1_PART3_ONCHANGE_PV_CH = "DAY1_PART3_ONCHANGE_PV_CH";
export const DAY1_VALIDATION_PV_CH = "DAY1_VALIDATION_PV_CH";


// -------------------------day2---------------------------------------
export const DAY2_PART2_PV_CH_ONCHANGE_IS_SELECTED = "DAY2_PART2_PV_CH_ONCHANGE_IS_SELECTED";
export const DAY2_PART1_PV_CH_ONCHANGE_IS_SELECTED = "DAY2_PART1_PV_CH_ONCHANGE_IS_SELECTED";
export const DAY2_PART4_PV_CH_ONCHANGE_IS_SELECTED = "DAY2_PART4_PV_CH_ONCHANGE_IS_SELECTED";
export const DAY2_VALID_DAY_PV_CH = "DAY2_VALID_DAY_PV_CH";
export const DAY2_PV_CH_GET_DETAIL = "DAY2_PV_CH_GET_DETAIL";
export const DAY2_GET_PV_CH_DETAIL_SUCCESS = "DAY2_GET_PV_CH_DETAIL_SUCCESS";
export const DAY2_GET_PV_CH_DETAIL_FAILED = "DAY2_GET_PV_CH_DETAIL_FAILED";

// ------------------------day 3----------------------------------------

export const DAY3_PART1_PV_CH_ONCHANGE_IS_SELECTED = "DAY3_PART1_PV_CH_ONCHANGE_IS_SELECTED";
export const DAY3_PART1_ONCHANGE_PV_CH = "DAY3_PART1_ONCHANGE_PV_CH";
export const DAY3_PART2_PV_CH_ONDRAG = "DAY3_PART2_PV_CH_ONDRAG";
export const DAY3_PART3_PV_CH_ONCHANGE_IS_SELECTED = "DAY3_PART3_PV_CH_ONCHANGE_IS_SELECTED";
export const DAY3_PART4_ONCHANGE_PV_CH = "DAY3_PART4_ONCHANGE_PV_CH";
export const DAY3_PART5_PV_CH_ONCHANGE_IS_SELECTED = "DAY3_PART5_PV_CH_ONCHANGE_IS_SELECTED";
export const DAY3_VALIDATION_PV_CH = "DAY3_VALIDATION_PV_CH";
export const DAY3_PV_CH_GET_DETAIL = "DAY3_PV_CH_GET_DETAIL";
export const DAY3_GET_PV_CH_DETAIL_SUCCESS = "DAY3_GET_PV_CH_DETAIL_SUCCESS";
export const DAY3_GET_PV_CH_DETAIL_FAILED = "DAY3_GET_PV_CH_DETAIL_FAILED";


// ----------------------------day 4------------------------------------

export const DAY4_PV_CH_ONCHANGE_CAT2 = "DAY4_PV_CH_ONCHANGE_CAT2";
export const DAY4_PV_CH_ONCHANGE_CAT1 = "DAY4_PV_CH_ONCHANGE_CAT1";
export const DAY4_PV_CH_ONCHANGE_CAT3 = "DAY4_PV_CH_ONCHANGE_CAT3";

export const DAY4_PART2_PV_CH_CHANGE_IS_SELECTED_RADIO = "DAY4_PART2_PV_CH_CHANGE_IS_SELECTED_RADIO";
export const DAY4_VALIDATION_PV_CH = "DAY4_VALIDATION_PV_CH";
export const DAY4_PV_CH_GET_DETAIL = "DAY4_PV_CH_GET_DETAIL";
export const DAY4_PV_CH_GET_DETAIL_SUCCESS = "DAY4_PV_CH_GET_DETAIL_SUCCESS";
export const DAY4_PV_CH_GET_DETAIL_FAILED = "DAY4_PV_CH_GET_DETAIL_FAILED";



export const DAY5_PV_CH_GET_DETAIL = "DAY5_PV_CH_GET_DETAIL";
export const DAY5_PV_CH_GET_DETAIL_SUCCESS = "DAY5_PV_CH_GET_DETAIL_SUCCESS";
export const DAY5_PV_CH_GET_DETAIL_FAILED = "DAY5_PV_CH_GET_DETAIL_FAILED";


export const GET_SCORE5_PV_CH = "GET_SCORE5_PV_CH";
export const GET_SCORE5_PV_CH_SUCCESS = "GET_SCORE5_PV_CH_SUCCESS";
export const GET_SCORE5_PV_CH_FAILED = "GET_SCORE5_PV_CH_FAILED";


export const GET_SCORE_PV_CH_GLOBAL = "GET_SCORE_PV_CH_GLOBAL";

export const SET_MODULE = "MODULE";
export const SET_SESSION = "SET_SESSION";





