import { Risks } from "./Risks";
import { Statistics } from "./Statistics";
import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

import HeadP from "../../../components/finEdge/HeadP";
import { getPortfolioIndicators } from "../../../redux/actions";
import style from "./style.module.scss";

const StatisticsRisks = () => {
  const { portfolio } = useSelector((state) => state.FinEdge.initialData);
  console.log("🚀 ~ file: index.jsx:15 ~ StatisticsRisks ~ portfolio:", portfolio)
  const [key, setKey] = useState("statistics");
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getPortfolioIndicators(portfolio?.id));
  }, []);
  return (
    <div>
      <div style={{ marginLeft: 68 }}>
        <Col>
          <h1 style={{ fontSize: 22, marginBottom: 27, marginTop: 50 }}>
            {t("finEdge.StatistiquesRisques.title")}
          </h1>
        </Col>

        <HeadP />
        <Col>

          <Tab.Container
            id="left-tabs-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Row>
              <Col style={{ marginLeft: "1px" }}>
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey={"statistics"} className="tab-anc">
                      {t("finEdge.StatistiquesRisques.Statistiques")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"risques"} className="tab-anc">
                      {t("finEdge.StatistiquesRisques.Risques")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey={"statistics"}>
                    {key === "statistics" && <Statistics t={t} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey={"risques"}>
                    {key === "risques" && <Risks t={t} />}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </Col>
      </div>
    </div>
  );
};

export { StatisticsRisks };
